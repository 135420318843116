import React, { useState, useRef, useEffect } from 'react';
import '../CSS file/ducumentsection.css';
import { MdOutlineFileUpload, MdClose, MdFileDownload } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocumentsToBeUploadedByUser, fetchDocumentsUploaded } from '../../StateManagement/slicers/userSlicer';
import { useAlert } from 'react-alert';

import { deleteUploadDocuments } from '../../Api/Api';
import { uploadUserDocuments, deleteUploadDocumentById } from '../../StateManagement/slicers/userSlicer';

import AlertBox from '../../Components/AlertBox';
import CustDialogBox from '../../Components/CustDialogBox';

export default function Content5() {
  const hiddenFileInput = React.useRef(null);
  const user = useSelector(state => state.user.user);
  const alert = useAlert();
  const documentsToBeUploadedByUser = useSelector(state => state.user.documentsToBeUploadedByUser);
  const uploadedDocumentsByUser = useSelector(state => state.user.uploadedDocumentsByUser);
  const [userDocument, setUserDocument] = useState([]);
  const [toggleModal, setToggleModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectDoc, setSelectDoc] = useState('');
  // const [isFilePicked, setIsFilePicked] = useState(false);
  const [documnetByValue, setDocumentByValue] = useState('');
  const fileNameType = useRef('');
  // const [fileUrl, setFileUrl] = useState({});
  // const [fileName, setFileName] = useState("");
  const [fileNameValue, setFileNameValue] = useState('');
  const chooseFile = useRef('');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDocumentsToBeUploadedByUser());
    dispatch(fetchDocumentsUploaded());
  }, []);

  useEffect(() => {
    let result = uploadedDocumentsByUser?.filter(item => {
      return item.user_id === user.id;
    });
    setUserDocument(result);
    setDocuments(documentsToBeUploadedByUser);
  }, [documentsToBeUploadedByUser, uploadedDocumentsByUser]);

  useEffect(() => {
    if (documents) {
      let data = [
        {
          id: 0,
          document_descriptio: 'Select Your Document Type',
          document_name: 'Select Your Document Type',
          document_type: 'Select Your Document Type',
          created_at: null,
          updated_at: null,
        },
      ];

      let newArray = data.concat(documents);
      setFileNameValue(newArray);
    }
  }, [documents]);

  async function handleFileOnServer() {
    if (chooseFile.current.value === '0') {
      alert.show('Please Select Document Type', {
        type: 'error',
      });
    } else {
      let fileUrl = hiddenFileInput.current.files[0];
      let fileName = hiddenFileInput.current.files[0].name;
      let documentId = chooseFile.current.value;
      const formData = new FormData();
      formData.append('document', fileUrl);
      formData.append('file_name', fileName);
      formData.append('user_id', user?.id);
      formData.append('document_id', documentId);
      dispatch(uploadUserDocuments(formData));
      // Reset the input fields after submission
      hiddenFileInput.current.value = '';
      chooseFile.current.value = '0';
    }
  }

  const handleAlert = () => {
    setToggleModal(true);
  };

  function handleDeleteDocument(e) {
    setToggleModal(false);
    setSelectDoc(e);

    dispatch(deleteUploadDocumentById(e));
    alert.success('deleted Document');
  }

  // const deleteDocs = () => {

  // }

  return (
    <>
      <section className="document_main_container">
        <div className="content_doc_main_container">
          {userDocument.map((perDocItem, index) => {
            return (
              <div className="document_box_container" key={index}>
                <p className="title_of_document_box">{perDocItem?.user_uploaded_documents_summary[0]?.document_name}</p>
                {/* <p className="subtitle_of_document_text">
                  {
                    perDocItem?.user_uploaded_documents_summary[0]
                      ?.document_description
                  }
                </p> */}

                <object
                  data={perDocItem?.document_url}
                  type="application/pdf"
                  // width="100%"
                  // height="300px"
                  style={{ border: '1px solid #ddd' }}
                >
                  <p>Your browser does not support viewing PDF files. Please download the PDF to view it.</p>
                </object>

                {/* <div className="upload_item_btn_container">
                  <div class="tooltip1">
                    File name :{" "}
                    {perDocItem?.file_name.length < 20
                      ? `${perDocItem?.file_name}`
                      : `${perDocItem?.file_name.substring(0, 20)}...`}
                    <div class="bottom1">
                      <span>{perDocItem?.file_name}</span>
                      <i></i>
                    </div>
                    <a href={perDocItem?.document_url}>Tap To View</a>
                  </div>
                </div> */}

                <div className="upload_item_btn_container">
                  <div className="link_close_btn_container">
                    <a href={perDocItem?.document_url} class="tooltip1" target="_blank">
                      <MdFileDownload />
                      {perDocItem?.file_name.length < 21
                        ? `${perDocItem?.file_name}`
                        : `${perDocItem?.file_name.substring(0, 21)}...`}
                      <div class="bottom1">
                        <span>{perDocItem?.file_name}</span>
                        <i></i>
                      </div>
                    </a>
                    <button
                      className="close_btn_doc_cancel"
                      onClick={() => {
                        setToggleModal(!toggleModal);
                        setSelectDoc(perDocItem.id);
                      }}
                    >
                      <MdClose />
                    </button>
                  </div>
                </div>
              </div>
            );
          })}

          {/* <AlertBox /> */}

          <CustDialogBox dialogHeaderName="Delete" open={toggleModal} onClose={() => setToggleModal(false)}>
            <AlertBox id={selectDoc} toggleModal={handleDeleteDocument} />
          </CustDialogBox>

          <div className="document_box_container">
            <p className="title_of_document_box">Upload Your Document</p>
            <p className="subtitle_of_document_text">Upload Your information</p>

            <div className="upload_item_btn_container">
              <select
                name="select_doc"
                id="select_doc"
                className="select_box_"
                ref={chooseFile}
                // value={fileNameValue}
                // onChange={() => {
                //   handleSelectOptionValue();
                //   fileNameType.current();
                // }}
              >
                {fileNameValue
                  ? fileNameValue.map(item => {
                      return (
                        <>
                          <option value={item.id}>{item.document_name}</option>
                        </>
                      );
                    })
                  : null}
              </select>

              <input
                type="file"
                id="myfile"
                name="myfile"
                ref={hiddenFileInput}
                accept=".pdf,.doc,.docx,.txt,.xls,.xlsx,.ppt,.pptx"
              />
              <button type="button" onClick={handleFileOnServer}>
                Submit
                {/* <MdOutlineFileUpload className="md_clear_icon" /> */}
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
