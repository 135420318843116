import React from 'react';
import '../CSS file/lefteditside.css';
import { BsThreeDots, BsKey } from 'react-icons/bs';
import pro_edit_pic from '../../assets/pro_pi_2.png';
import { MdOutlineMailOutline, MdOutlinePersonOutline, MdPhoneAndroid, MdPhone } from 'react-icons/md';
import EditProfileHeader from './EditProfileHeader';
import { useSelector } from 'react-redux';
import LoadMoreText from '../../utils/LoadMoreText';

export default function EditProfileLeftSide() {
  const user = useSelector(state => state.user.user);

  const twoIcons = [
    {
      numberIcon: <MdOutlineMailOutline className="edit_icon_style" />,
      numberText: user?.email,
    },
    {
      numberIcon: <MdPhone className="edit_icon_style" />,
      numberText: user?.user_personal_profile?.phone ? user?.user_personal_profile?.phone : 'enter your phone number',
    },
    {
      numberIcon: <MdPhoneAndroid className="edit_icon_style" />,
      numberText: user?.user_personal_profile?.alternate_phone
        ? user?.user_personal_profile?.alternate_phone
        : 'enter your mobile number',
    },
  ];

  console.log({ user });

  return (
    <div className="left_side_under_container">
      <div className="profile_details_container">
        <EditProfileHeader editHeaderName="Your details" edit="urDetails" dlt="dlt" />

        <div className="edit_prfile_photo_container">
          <img
            src={user?.user_personal_profile?.profile_pic ? user?.user_personal_profile?.profile_pic : pro_edit_pic}
            className="profile_photo_edit"
            alt="Edit_pic"
            loading="lazy"
          />
        </div>

        <div className="name_username_about_text_contianer">
          <h6 className="title_text_edit">
            {user?.user_personal_profile?.first_name || user?.user_personal_profile?.last_name
              ? user?.user_personal_profile?.first_name + ' ' + user?.user_personal_profile?.last_name
              : 'Hello User'}
          </h6>
          {user?.username ? <h6 className="user_name_edit_text">{user?.username}</h6> : null}

          {/* <p className="extra_edit_text">
            {user?.user_personal_profile?.description
              ? user?.user_personal_profile?.description
              : null}
          </p> */}
          <LoadMoreText
            text={user?.user_personal_profile?.description ? user?.user_personal_profile?.description : null}
            maxLength={80}
          />
        </div>

        {twoIcons.map(perIcon => {
          return (
            <div className="two_icon_text_two_contianer" key={Math.random()}>
              {perIcon.numberIcon}
              <h6 className="number_edit_text">{perIcon.numberText}</h6>
            </div>
          );
        })}
      </div>

      <div className="two_under_container">
        <EditProfileHeader editHeaderName="Account details" edit="accDetails" dlt="dlt" />

        <div className="two_text_two_login_contianer">
          <div className="two_icon_text_two_contianer1">
            <MdOutlinePersonOutline className="edit_icon_style" />
            {/* <h6 className="number_edit_text">abc@xyxgmail.com</h6> */}
            <h6 className="number_edit_text">{user?.email}</h6>
          </div>

          <div className="two_icon_text_two_contianer1">
            <BsKey className="edit_icon_style" />
            <h6 className="number_edit_text">
              <BsThreeDots className="edit_icon_style1" />
              <BsThreeDots className="edit_icon_style1" />
              <BsThreeDots className="edit_icon_style1" />
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}
