import React, { useState, useEffect } from 'react';
import './boxtextsubtxt.css';
import { useDispatch, useSelector } from 'react-redux';
import { storeUniversityTypeToRender } from '../../StateManagement/slicers/universitySlicer';
import { storeCourseTypeToRender } from '../../StateManagement/slicers/courseSlicer';
import { useNavigate } from 'react-router-dom';

function BoxTextSubText(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const university = useSelector(state => state.university.university);

  const [featuredLists, setFeaturedLists] = useState([]);
  const [message, setMessage] = useState('');
  const { featuredUniversities, popularUniversities, recommendedUniversities, tabToRender } = useSelector(
    state => state.university,
  );

  useEffect(() => {
    if (university) {
      if (university?.universities?.length > 0) {
        if (props.sectionUniversityCourseName === 'Universities') {
          let uni = university.universities.slice(0, 5);

          setFeaturedLists(uni);
        }
      }
    }
    if (featuredUniversities) {
      if (featuredUniversities?.universities?.length > 0) {
        if (props.sectionUniversityCourseName === 'Featured Univercities') {
          let uni = featuredUniversities.universities.slice(0, 5);

          setFeaturedLists(uni);
        }
      }
    }
    if (popularUniversities) {
      if (popularUniversities?.universities?.length > 0) {
        if (props.sectionUniversityCourseName === 'Popular Universities') {
          let uni = popularUniversities.universities.slice(0, 5);

          setFeaturedLists(uni);
        }
      }
    }
    if (recommendedUniversities) {
      if (recommendedUniversities) {
        if (props.sectionUniversityCourseName === 'Recommended Univercities') {
          if (recommendedUniversities?.messages === 'No Recommended Universities Found') {
            setMessage('No Data Found');
          } else {
            if (recommendedUniversities?.data?.total_universities > 0) {
              let uni = recommendedUniversities?.data?.universities.slice(0, 5);

              setFeaturedLists(uni);
            }
          }
        }
      }
    }
  }, [featuredUniversities, popularUniversities, recommendedUniversities]);

  function handleViewButton(data) {
    if (tabToRender === 1) {
      dispatch(
        storeUniversityTypeToRender({
          type: data,
          tab: 1,
        }),
      );
    }
    if (tabToRender === 2) {
      dispatch(storeCourseTypeToRender(data));
      dispatch(
        storeUniversityTypeToRender({
          type: data,
          tab: 2,
        }),
      );
    }
    if (tabToRender === 3) {
      dispatch(storeCourseTypeToRender(data));
      dispatch(
        storeUniversityTypeToRender({
          type: data,
          tab: 3,
        }),
      );
    }
  }

  function handleViewDetails(e) {
    const univercityId = e?.id;
    const univercityName = e?.u_name;
    const path = `/UnivercityMain/ViewDetailUnivercity/${univercityId}/${univercityName}`;
    navigate(path);
  }

  const renderImgTitle = featuredLists.map(perTextValue => {
    if (perTextValue) {
      return (
        <button
          className="profile_event_title_sub_title_container"
          key={Math.random()}
          onClick={() => {
            handleViewDetails(perTextValue, 'perTextValue');
          }}
        >
          <div className="event_box_img_contianer">
            <img src={perTextValue?.university_logo} loading="lazy" className="univer_img_popular_self" />
          </div>
          <div className="univer_title_text_subtitle_contianer">
            <h6 className="event_title_text" title={perTextValue.u_name}>
              {perTextValue?.u_name.substring(0, 30)}...
            </h6>
          </div>
        </button>
      );
    }
  });
  return (
    <>
      <div className="dicover_by_postion_container ">
        <div className="conectin_txt_contianer">
          <h6 className="people_main_title_text">{props.sectionUniversityCourseName}</h6>
          <a
            href="#view"
            onClick={() => {
              handleViewButton(props.sectionUniversityCourseName);
            }}
          >
            View all
          </a>
        </div>
        {featuredLists.length > 0 || message === 'No Data Found'
          ? message === 'No Data Found'
            ? 'No Data Found'
            : renderImgTitle
          : 'No Data Found'}
      </div>
    </>
  );
}

export default BoxTextSubText;
